*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;

}

.main{
   min-height: 88vh;
}

section:nth-child(1){
   background: lime;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
}

section:nth-child(2){
   background: rgb(247, 0, 255);
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
}


section:nth-child(3){
   background: rgb(255, 215, 17);
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
}